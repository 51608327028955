const affLinks = [
  
 
  {
    title: "Scooter",
    description: "Our daughter, Noelle is pretty adventurous and I thought this would be a fun way to learn to balance. I got her a helmet and pads last year that we'll use with it!",
    imageLink: '<a href="https://www.amazon.com/gp/product/B07TBR5S6B/ref=as_li_ss_il?ie=UTF8&psc=1&linkCode=li2&tag=&linkId=6956003a7b559d196ddc9bdff3ff9ae0&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07TBR5S6B&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=&language=en_US&l=li2&o=1&a=B07TBR5S6B" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/34aWKtG',
    tags: ['christmas','gift','toddler','fun','outdoor'],
  },
  {
    title: "Helmet",
    description: "We got this for Noelle last year to go with her balance bike but she'll also use it with her scooter! ",
    imageLink: '<a href="https://www.amazon.com/gp/product/B07MFN4SRG/ref=as_li_ss_il?ie=UTF8&th=1&linkCode=li2&tag=giftideas04d0-20&linkId=043957b514424d614f4fce4b6210fb52&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07MFN4SRG&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07MFN4SRG" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/37Zi9ao',
    tags: ['toddler','safety','gift','outdoor','fun'],
  },
  {
    title: "Balance Bike",
    description: "We got this at Costco last year. I'm excited to see they have it on amazon too! This was Noelle's big Christmas gift last year and she's just now tall enough to reach the ground. When I was a kid tricycles were all the rage but they only taught you the easy part, how to pedal. Now it seems balance bikes are a lot more popular because balance is what the kids need to learn. If you can balance, you'll be able to learn how to pedal easily.",
    imageLink: '<a href="https://www.amazon.com/Chillafish-Integrated-Footrest-Footbrake-Rubberskin/dp/B07LD66LF2/ref=as_li_ss_il?dchild=1&keywords=toddler+balance+bike+bmxie&qid=1601183786&sr=8-3&linkCode=li2&tag=giftideas04d0-20&linkId=7b6a8f1ba4d35866d7f322fcd7e6798f&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07LD66LF2&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07LD66LF2" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3gNIaxq',
    tags: ['fun','gift','outdoor','christmas','toddler'],
  },
  {
    title: "Parachute",
    description: "Parachutes are not just for elementary gym class! I had no idea you could actually buy one and they're pretty cheap! There are a lot of different sizes but we wanted to make sure we got one we could handle with just the 3 of us.",
    imageLink: '<a href="https://www.amazon.com/gp/product/B07W1X7N6S/ref=as_li_ss_il?ie=UTF8&psc=1&linkCode=li2&tag=giftideas04d0-20&linkId=48ae5296c93be59db15d57c44780cb0d&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07W1X7N6S&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07W1X7N6S" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3nkjONY',
    tags: ['toy','gift','toddler','fun','kid'],
  },
  {
    title: "Rock What Ya Got - Book",
    description: "We borrowed this book from the library and it had such a positive message that I knew I needed to add it to our collection.  Noelle will be getting this book for Christmas this year.  It's all about loving who you are, and \"rocking what you got\".",
    imageLink: '<a href="https://www.amazon.com/gp/product/0316561509/ref=as_li_ss_il?ie=UTF8&psc=1&linkCode=li2&tag=giftideas04d0-20&linkId=8ca73d408c5cbd858f9cd15e390678cc&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=0316561509&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=0316561509" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3njZ60T',
    tags: ['christmas','gift','educational','book','kid','toddler'],
  },
  {
    title: "Fox in Socks - Book",
    description: "Like most books we end up buying, we borrowed this from the library after remembering it from childhood. My husband loves the challenge of reading this to Noelle as fast as possible without getting tounge tied. It's a fun Dr. Seuss book.",
    imageLink: '<a href="https://www.amazon.com/gp/product/0394800389/ref=as_li_ss_il?ie=UTF8&psc=1&linkCode=li2&tag=giftideas04d0-20&linkId=949f064efe0efa74df9f9cc189b245fc&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=0394800389&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=0394800389" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3gP5LxI',
    tags: ['book','gift','christmas','toddler','baby'],
  },
  {
    title: "Play Kitchen",
    description: "Noelle's grandma got her a play kitchen last year and she loves pretending to cook. I can't find the exact one that she has but this is the same brand and looks like the newer version. These toy kitchens have been getting fancier and fancier!! It's a great toy to teach an important life skill.",
    imageLink: '<a href="https://www.amazon.com/Step2-Contemporary-Chef-Kitchen-Playset/dp/B01D8MSY7C/ref=as_li_ss_il?dchild=1&keywords=step+2+kitchen&qid=1607962965&sr=8-10&linkCode=li2&tag=giftideas04d0-20&linkId=3a280f0726fcf8b86e2d638279f037e1&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B01D8MSY7C&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B01D8MSY7C" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3mkr0sd',
    tags: ['toy','gift','christmas','toddler'],
  },
  {
    title: "Slide",
    description: "Noelle got this slide when she was 13 months old and could climb up the ladder, and go down the slide on her own by 15 months. We have used it inside and outside but currently have it inside. It can fold up too if you do need to put it away!",
    imageLink: '<a href="https://www.amazon.com/Little-Tikes-First-Slide-Blue/dp/B008MH5H4M/ref=as_li_ss_il?dchild=1&keywords=slide&qid=1601177216&sr=8-1&linkCode=li2&tag=giftideas04d0-20&linkId=cf43376846177f2765774024d4889491&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B008MH5H4M&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B008MH5H4M" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/2JT5nlN',
    tags: ['toy','gift','christmas','outdoor','toddler'],
  },
  {
    title: "Water Table",
    description: "I know water tables are usually looked at as a summer time purchase, but hear me out! Unless your child has a summer birthday, Christmas is the time to get fun things for the whole year! Noelle got this from her grandma last Christmas and while it did sit in the box for a few months, as soon as it warmed up in May, we were ready to go and she was screaming with excitement.",
    imageLink: '<a href="https://www.amazon.com/Step2-874600-Showers-Playset-Multi-Colored/dp/B01K1K0K6M/ref=as_li_ss_il?dchild=1&keywords=water+table&qid=1601177348&sr=8-2&linkCode=li2&tag=giftideas04d0-20&linkId=26175f43ee94d86c9788a573c0ba275b&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B01K1K0K6M&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B01K1K0K6M" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/37fQMcM',
    tags: ['toy','gift','christmas','outdoor','toddler'],
  },
  {
    title: "Mega Blocks",
    description: "Mega Blocks are fun for all ages. Noelle destroyed the guy I was building the other day and I'm still bummed about it. Every time I added a layer she came and undid it so I'm tempted to bring down her bucket of mega blocks and play with them while she's sleeping. I don't even think I'm kidding lol. She can connect the pieces now and while she doesn't really design anything specific, I know that's coming soon.",
    imageLink: '<a href="https://www.amazon.com/Mega-Bloks-Building-Pink-Piece/dp/B007GE75G0/ref=as_li_ss_il?dchild=1&keywords=toddler+blocks&qid=1601177548&sr=8-1&linkCode=li2&tag=giftideas04d0-20&linkId=64ac2ff84c0cded6eecc0e07e414f01f&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B007GE75G0&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B007GE75G0" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3nl8Sjb',
    tags: ['toy','gift','christmas','toddler'],
  },
  {
    title: "Beanie with Pom",
    description: "My daughter, Noelle, loves hats and I coudln't resist this one. The pompom even snaps off for the wash!  I've never seen that before!  I ordered the adult size for myself because I liked it so much!  ",
    imageLink: '<a href="https://www.amazon.com/gp/product/B07VC5BNFN/ref=as_li_ss_il?ie=UTF8&psc=1&linkCode=li2&tag=giftideas04d0-20&linkId=3169e689efc47e187fe722e9ab95e963&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07VC5BNFN&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07VC5BNFN" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/34cGjNh',
    tags: ['toddler','gift','clothes','winter','kid'],
  },
  {
    title: "Name Puzzle",
    description: "My best friend got this name puzzle for Noelle's first Christmas. It's a really thoughtful gift because it can be used as a decoration if they're too young to use it, and then it becomes very educational. It not only teaches how to do a puzzle, but how to spell their name!",
    imageLink: '<a href="https://www.amazon.com/Fat-Brain-Toys-Wooden-Personalized/dp/B01MYM7GPD/ref=as_li_ss_il?dchild=1&keywords=name+puzzle&qid=1601177667&sr=8-1-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUEzQkU1TUJJVEVDQzA1JmVuY3J5cHRlZElkPUEwNzIwOTU3MTBGSjVUMEZZMlFUNCZlbmNyeXB0ZWRBZElkPUEwNjA4OTEwM0pVNzVIRTRBR0I1UiZ3aWRnZXROYW1lPXNwX2F0ZiZhY3Rpb249Y2xpY2tSZWRpcmVjdCZkb05vdExvZ0NsaWNrPXRydWU=&linkCode=li2&tag=giftideas04d0-20&linkId=8a34d8a81ccb55ee6ad3a21a905ef6f0&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B01MYM7GPD&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B01MYM7GPD" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3gJCR1V',
    tags: ['toy','gift','christmas','puzzle','toddler'],
  },
  {
    title: "Basketball Hoop",
    description: "I can't believe this product hasn't changed since I was a kid. The only thing I can't remember is if mine came with 3 balls! We play with this in the backyard a lot BUT it's definitely small enough that you could play with it indoors too if you get cold winters and have a safe spot for it. My husband and I used it to play HORSE the other day while Noelle watched lol.",
    imageLink: '<a href="https://www.amazon.com/Little-Tikes-EasyScore-Basketball-Set/dp/B001BKX0V4/ref=as_li_ss_il?dchild=1&keywords=little+tikes+basketball+hoop&qid=1607963648&s=toys-and-games&sr=1-2&linkCode=li2&tag=giftideas04d0-20&linkId=1a8871ab3fa239fef95d9598950e9993&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B001BKX0V4&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B001BKX0V4" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3gNsCJH',
    tags: [''],
  },
  {
    title: "Aqua Doodle Mat",
    description: "I try to encourage Noelle to be creative but sometimes messy activies aren't practicle. When I want her to artistic but can't give my full focus, I pull out our aqua mat. You fill up the pens with water and there's no mess.",
    imageLink: '<a href="https://www.amazon.com/Piggy-G-Colouring-Educational-Christams-Children/dp/B088338TDT/ref=as_li_ss_il?dchild=1&keywords=aquadoodle&qid=1601184023&sr=8-33&linkCode=li2&tag=giftideas04d0-20&linkId=5a0e871f4ecb8426795c749c7a2952f8&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B088338TDT&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B088338TDT" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/2KpotQl',
    tags: ['christmas','toddler','gift','art'],
  },
  {
    title: "Crayola Crayons",
    description: "I don't care what you say, no crayon will ever beat a crayola crayon. You can probably get them cheaper at the grocery store or something, but a fresh box of crayola crayons is the ultimate stocking stuffer.",
    imageLink: '<a href="https://www.amazon.com/Crayola-Classic-Color-Pack-Crayons/dp/B002CVTBO6/ref=as_li_ss_il?dchild=1&keywords=crayola+crayons&qid=1607963810&sr=8-2-spons&psc=1&smid=ASEVS99O6FS73&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUFYRE9UN0ZGVDFVQzMmZW5jcnlwdGVkSWQ9QTAzODkxNzI5VTRNWVhaSElMMDcmZW5jcnlwdGVkQWRJZD1BMDAwNDU5MzFKTExFVDYwNTVHVjAmd2lkZ2V0TmFtZT1zcF9hdGYmYWN0aW9uPWNsaWNrUmVkaXJlY3QmZG9Ob3RMb2dDbGljaz10cnVl&linkCode=li2&tag=giftideas04d0-20&linkId=be6129c616a4383690cc4245bad50613&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B002CVTBO6&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B002CVTBO6" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3mgIjKH',
    tags: ['stockingstuffer','gift','christmas','toddler','kid','art'],
  },
  {
    title: "Pajamas",
    description: "Don't forget that gifts don't all have to be toys! I always like to mix in things that Noelle needs. I love these pajamas, she has had diferent designs in each size. They seems so soft and comfy and I keep checking to see if they come in my size haha.",
    imageLink: '<a href="https://www.amazon.com/gp/product/B07PYLS858/ref=as_li_ss_il?ie=UTF8&psc=1&linkCode=li2&tag=giftideas04d0-20&linkId=2259ca9a27db26d6432d24bc6496da4c&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07PYLS858&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07PYLS858" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3gLQHAF',
    tags: ['pajamas','clothes','gift','toddler'],
  },
  {
    title: "Hair Ties",
    description: "There are a million hair ties in the world but these are tiny and soft and stay in Noelle's really thin hair really well UNTIL she rips them out. I'm happy with this brand but haven't really tried any other toddler brand at this time.  They'd be perfect for a stocking stuffer! ",
    imageLink: '<a href="https://www.amazon.com/gp/product/B07PYFBGF1/ref=as_li_ss_il?ie=UTF8&th=1&linkCode=li2&tag=giftideas04d0-20&linkId=b375d60ba8cf496053bad7be8698504c&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07PYFBGF1&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07PYFBGF1" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3nhTVP3',
    tags: ['clothes','stocking','toddler','hair'],
  },
  {
    title: "Kick Balls",
    description: "I got a pack of these for Noelle for Easter this year. We keep one inside and the rest are for kicking in the yard. She learned how to kick a ball really young and I think these helped. They're the perfect size for small children, much small than a standard kickball. 6 is really way too many for one child but when I bought them single ones were almost the same price so it was worth it!",
    imageLink: '<a href="https://www.amazon.com/dp/B07D9TZHVM/ref=as_li_ss_il?ie=UTF8&linkCode=li2&tag=giftideas04d0-20&linkId=7e804af7e8eff12e7e83bb3c7e2adc1c&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07D9TZHVM&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07D9TZHVM" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/37f8PQi',
    tags: ['kid','toddler','christmas','outdoor','fun','toy'],
  },
  {
    title: "Baby Doll",
    description: "It's important that not all of your child's dolls look exactly like them. Noelle's first word was \"baby\" and her baby dolls are definitely her favorite toys. This doll is pretty small and really easy for her to carry her around. She woudn't put her down for the first week she had her, she loves her so much.  They have a few different ethnicities.",
    imageLink: '<a href="https://www.amazon.com/dp/B01MREMJ90/ref=as_li_ss_il?coliid=I2DWQ5YF8NC1GE&colid=ZUZN8BTRHWVE&ref_=lv_ov_lig_dp_it&th=1&linkCode=li2&tag=giftideas04d0-20&linkId=45ca1ae99ecbd6a38dde962e0b378128&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B01MREMJ90&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B01MREMJ90" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/2WgD5Eh',
    tags: ['toddler','gift','christmas','fun'],
  },
  {
    title: "Elmo Stuffed Animal",
    description: "I like buying Noelle toys of her favorite characters. Before Covid, we were on a playdate where she saw a stuffed elmo. She had been watching him on TV for a bit so she knew who he was right away. She carried him around hugging him for 10 minutes straight so I went home and ordered this little one. She loves him so much.",
    imageLink: '<a href="https://www.amazon.com/gp/product/B0054KBV4U/ref=as_li_ss_il?ie=UTF8&psc=1&linkCode=li2&tag=giftideas04d0-20&linkId=077bbee6b7be5ba8bb98f59d3b938648&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0054KBV4U&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B0054KBV4U" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/381MuVN',
    tags: ['toy','baby','toddler','christmas'],
  },
  {
    title: "Toddler Table",
    description: "This makes a PERFECT big Christmas gift for a one year old.  It's large, but very functional! Noelle had been really figthing her booster seat at meal time. She was throwing food on the floor and refusing to sit down. I read that having a table their own size could help. I felt weird about her being so much lower than us but I decided to give this one a try. It's really beautiful and Noelle certainly loves it. It has not completely solved our meal time struggle because she gets up from the table frequesntly, but we are working on that. She carries her chairs all around the house with her which is really cute. She does a really good job coloring or doing other activies at this table and I'm really happy with it.",
    imageLink: '<a href="https://www.amazon.com/gp/product/B08341J88P/ref=as_li_ss_il?ie=UTF8&psc=1&linkCode=li2&tag=giftideas04d0-20&linkId=7ddbf3fb433d430477069f9b2cfd3ae1&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08341J88P&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B08341J88P" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/2KlVpcu',
    tags: ['toddler','christmas','gift'],
  },
  {
    title: "Toy Remote",
    description: "This is a must if your child steals your remote control and loses it daily. We got this for Noelle as a distraction when she was a baby because she loves to push the buttons. It sings some ridiculous songs and is really weird but we love it because of that. Lol",
    imageLink: '<a href="https://www.amazon.com/gp/product/B07FK7B1BY/ref=as_li_ss_il?ie=UTF8&psc=1&linkCode=li2&tag=giftideas04d0-20&linkId=27734d984ff6dd14d8d33bdbe3094931&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07FK7B1BY&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07FK7B1BY" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/2K6eNKH',
    tags: ['toddler','baby','toy','christmas'],
  },
  {
    title: "Toy Organizer",
    description: "This is also availble in deeper primary colors. We got this for Noelle for Christmas last year. We put it together and wrapped it in wrapping paper. It was huge and really added to the pile of presents. It's a great way to organize her toys so that they're out of the way but still on display. Don't worry... she pulls everything out of the buckets within minutes but we are trying to work on putting things away when you're done playing.  She'll catch on soon, right? RIGHT GUYS??!! RIGHT?!!!",
    imageLink: '<a href="https://www.amazon.com/gp/product/B07FK6BRVN/ref=as_li_ss_il?ie=UTF8&psc=1&linkCode=li2&tag=giftideas04d0-20&linkId=56a1cc5ff5a40b4eb70b2571ab0dbca8&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07FK6BRVN&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07FK6BRVN" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3r5cgRu',
    tags: ['toy','kid','toddler','christmas'],
  },
 
  {
    title: "Melissa and Doug Cleaning Set",
    description: "Noelle loves to pretend she's cleaning the house! I like it too becuase I think it's helping her develop some real life skills. She likes to help with the real broom too, but these are much easier to manage and a lot more fun for her!",
    imageLink: '<a href="https://www.amazon.com/gp/product/B07PBXRJ7G/ref=as_li_ss_il?ie=UTF8&psc=1&linkCode=li2&tag=giftideas04d0-20&linkId=7ee7c459ddddd9fc5e1876ad0318cad5&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07PBXRJ7G&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07PBXRJ7G" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3mlZDhz',
    tags: [],
  },
  {
    title: "Velcro Food",
    description: "I originally ordered these because I thought they'd be a great prop for our food stand tiktok's but Noelle has learned so much from this toy!! She has learned how to use velcro, how to match two things together, and even how to cut! It comes with a very dull fake knife that you use to cut the pieces of food and she mastered it within 2 days! I think it's really teaching her how to cut food safely, maybe she will be able to help prep dinner soon!",
    imageLink: '<a href="https://www.amazon.com/gp/product/B01LERAALQ/ref=as_li_ss_il?ie=UTF8&psc=1&linkCode=li2&tag=giftideas04d0-20&linkId=fe5b4234891fc32fffce6fd0cda78b9d&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B01LERAALQ&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B01LERAALQ" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3mutJ29',
    tags: ['toy','toddler','christmas'],
  },
  {
    title: "Musical Instruments",
    description: "I got this for Noelle for her 2nd birthday.  Pre-covid, we used to go to a music class at the library and a lot of these instruments were similar to the ones she used there.  These are REALLY good quality.  Everything is wooden and I'm just really impressed.  I know buying a noisy toy for your own kid is a risk, but I want her to be able to express herself with sound.",
    imageLink: '<a href="https://www.amazon.com/gp/product/B089CY6SGN/ref=as_li_ss_il?ie=UTF8&psc=1&linkCode=li2&tag=giftideas04d0-20&linkId=af06f6503f7b140a7caa8471123e88cd&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B089CY6SGN&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B089CY6SGN" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3gTIoTr',
    tags: ['toddler','gift','kid','music'],
  },
  {
    title: "Anna Doll - Frozen",
    description: "I got this for Noelle on Costco's website, but not everyone has a Costco membership AND their inventory turns over very quickly.  I was glad to see that it was avaialble on amazon as well because she LOVES this doll.  I never truly understood the Frozen hype until Noelle started asking to watch it almost daily.  It's actually a decent movie and this is a pretty good doll.  They have other Disney characters like this as well!",
    imageLink: '<a href="https://www.amazon.com/Disney-Princess-Tea-time-Anna/dp/B07Y5PC4SV/ref=as_li_ss_il?dchild=1&keywords=anna+tea+time&qid=1605106497&sr=8-1&th=1&linkCode=li2&tag=giftideas04d0-20&linkId=39450152166d42f13b5722615270bf4a&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07Y5PC4SV&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07Y5PC4SV" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/2IZqGSa',
    tags: ['toddler','gift','christmas','toy','doll','disney'],
  },
  
  {
    title: "Follow-Me Olaf",
    description: "I ordered this for my daughter for Christmas.  I kept seeing commercials on YouTube for this Olaf doll that follows you around if you shine his flashlight on the floor.  When I shop for toddler gifts I try to think back to how I'd like something when I was little and this just seems so darn cool!  Not to mention Noelle's OBSESSED with Frozen.",
    imageLink: '<a href="https://www.amazon.com/Frozen-Disney-Follow-Me-Friend-Olaf/dp/B07RVBSY57/ref=as_li_ss_il?dchild=1&keywords=follow+me+friend+olaf&qid=1605108453&s=toys-and-games&sr=1-4&linkCode=li2&tag=giftideas04d0-20&linkId=fc3987fe54da6a1b1ea4bf035ecb440f&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07RVBSY57&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07RVBSY57" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3airWej',
    tags: ['toddler','kid','christmas','toy','doll','disney'],
  },
  {
    title: "Blippi Costume",
    description: "Is anyone else's toddler obsessed with Blippi? We gave this to Noelle for her 2nd birthday and the fact that she could dress up as Blippi completely made her day!  This is a lot of fun and a great addition to the \"dress-up\" wardrobe.  ",
    imageLink: '<a href="https://www.amazon.com/Blippi-Dress-Up-Set-Suspenders/dp/B07Y91NDJB/ref=as_li_ss_il?dchild=1&keywords=blippi+outfit&qid=1605108747&s=toys-and-games&sr=1-1&linkCode=li2&tag=giftideas04d0-20&linkId=2e0b0f9760fa3cf57dcba1fc78b490fc&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07Y91NDJB&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07Y91NDJB" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3mm0ejj',
    tags: ['toddler','gift','dressup','fun'],
  },
  {
    title: "Wooden Blocks - Melissa and Doug",
    description: "They say stacking blocks is a really important form of play for toddlers.  I think it helps them with hand eye coordination, creativity, and problem solving.  I wanted to get Noelle a really nice set of wood blocks and Melissa and Doug was the answer, as always.  This will be a Christmas gift, so she hasn't played with it yet but I'm pretty excited about this find.",
    imageLink: '<a href="https://www.amazon.com/gp/product/B000068CKZ/ref=as_li_ss_il?ie=UTF8&psc=1&linkCode=li2&tag=giftideas04d0-20&linkId=6928327bff119fcb77ac3a271586914d&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B000068CKZ&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B000068CKZ" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/2LErJaZ',
    tags: ['toddler','toy','christmas','gift'],
  },
  {
    title: "Stacking Rainbow",
    description: "I have seen these wooden rainbow things all over the place so I decided to get one for Noelle for her 2nd birthday.  I really like the wooden toys over plastic and this is really colorful.  It looks nice sitting on the shelf and Noelle does like it but at 24 months she doens't quite know what to do with it.  She is not good as stacking it and gets a little frustrated without help but I like toys that make her think.  Many of the toys she's struggled with in the past, she mastered within weeks.  I know that with some more practice this will help her be creative.",
    imageLink: '<a href="https://www.amazon.com/gp/product/B077P345JL/ref=as_li_ss_il?ie=UTF8&psc=1&linkCode=li2&tag=giftideas04d0-20&linkId=714e2b6ea86b0e82bfe879f93f78aefb&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B077P345JL&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B077P345JL" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/38bpoMj',
    tags: ['toddler','gift','christmas','toy'],
  },
  {
    title: "Bamboo Plates",
    description: "Don't forget that holiday gifts can also be practical things!  Maybe it's time to upgrade to a \"big kid\" plate? We used to use those plates with the 3 different sections but I noticed Noelle was starting to make sure each food item remained in it's separate space and it's complicated to ensure food stays separate as an adult so I wanted to get her used to regular plates with no dividers. I decided to get these because I am trying to eliminate plastic from our life for a number of reasons. I thought they were really cute, and they're not nearly as dangerous or breakable as most other plate materials such as ceramic or glass. She really loves her 'big girl plates' as I call them.  We also have the matching bowls and cups!",
    imageLink: '<a href="https://www.amazon.com/gp/product/B07QG7W33P/ref=as_li_ss_il?ie=UTF8&psc=1&linkCode=li2&tag=giftideas04d0-20&linkId=1024339e15479949ba05ccaa31c2cbcd&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07QG7W33P&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07QG7W33P" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3qZ26SF',
    tags: ['toddler','mealtime','christmas','kid'],
  },
  {
    title: "Toddler Forks",
    description: "Noelle was using those soft baby spoons for a while but they made it very difficult for her to feed herself and she always wanted to try ours which were way too big for her mouth. I got the forks and spoons from this brnad and she loves them! They are just like real silverware, but smaller.  I will probably try the knives when I think she's ready. ",
    imageLink: '<a href="https://www.amazon.com/TeamFar-Utensils-Silverware-Stainless-Dishwasher/dp/B07QMQLVTT/ref=as_li_ss_il?ref_=ast_sto_dp&linkCode=li2&tag=giftideas04d0-20&linkId=f7301bf763662e557d889ef8624b7ed7&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07QMQLVTT&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07QMQLVTT" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/34jCINM',
    tags: ['toddler','stocking','christmas','mealtime'],
  },
  {
    title: "Felt Christmas Tree",
    description: "I wanted Noelle to have her own Christmas tree that she's allowed to touch as often as she wants.  This will be the first year that I'm seriously concerned about potential tree climbing or even worse so maybe this will help divert her attention from the real Christmas tree??  Probably not, but it's worth a try!  I thought this was a really cute and safe toddler tree that she can call her very own.",
    imageLink: '<a href="https://www.amazon.com/gp/product/B07J6BJ26S/ref=as_li_ss_il?ie=UTF8&psc=1&linkCode=li2&tag=giftideas04d0-20&linkId=c4828135c47f516983610a9145b0da60&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07J6BJ26S&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07J6BJ26S" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/2IUvZSK',
    tags: ['toddler','christmas','decoration','kid'],
  },
  {
    title: "Baby Bling Bows",
    description: "I am obsessed with baby bling bows and have collecting them for Noelle since I found out about them.  I used to not at all care about brands of anything but these are so strechy and even fit me so they never left marks.  She wears them less now since we don't go anywhere (thanks covid!) but she still likes to pick out a bow of the day when she's in the right mood.  These are a perfect gift for a new baby girl.",
    imageLink: '<a href="https://www.amazon.com/Baby-Bling-2-Pack-Shabby-Toddler/dp/B07SYQPCH4/ref=as_li_ss_il?dchild=1&keywords=baby+bling+bow+shabby&qid=1595790298&sr=8-4&linkCode=li2&tag=giftideas04d0-20&linkId=56db6ef79b3e013aa3bfb88a315c2110&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07SYQPCH4&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07SYQPCH4" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3oVEWuo',
    tags: ['toddler','gift','stocking','clothing'],
  },
  
  {
    title: "Princess Castle Playhouse",
    description: "Our friend sent this to Noelle for her birthday and it is a HIT! She loves to sit in her tent and look up at the stars and play.  The floor was COVERED in toys on day one but at least they're contained?  We are lucky to have such thoughtful friends.",
    imageLink: '<a href="https://www.amazon.com/Orian-Princess-Castle-Playhouse-Lights/dp/B07TV8HCMT/ref=as_li_ss_il?dchild=1&keywords=toddler+tent&qid=1605110523&sr=8-4-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUExTDhNVzFKUUVTV0ZXJmVuY3J5cHRlZElkPUEwODc4MzUxMUI1VUk0OTVFV1BRSyZlbmNyeXB0ZWRBZElkPUEwNDkxNjcwRTM0TEw3VVlKOFJEJndpZGdldE5hbWU9c3BfYXRmJmFjdGlvbj1jbGlja1JlZGlyZWN0JmRvTm90TG9nQ2xpY2s9dHJ1ZQ==&linkCode=li2&tag=giftideas04d0-20&linkId=e98eb6de9c3c668592affaa5e5b4ebce&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07TV8HCMT&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07TV8HCMT" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/2K2nd5W',
    tags: ['toddler','gift','christmas','toy'],
  },
  
  {
    title: "Bubble Lawnmower",
    description: "These bubble lawn mowers are so much fun! We don't have one yet.  I have memories of using a friend's as a kid, running through the bubbles with pure joy.  If only it really mowed the lawn, right?",
    imageLink: '<a href="https://www.amazon.com/Lydaz-Electronic-Machine-Outdoor-Toddlers/dp/B07H331J4R/ref=as_li_ss_il?dchild=1&keywords=toddler+boy&qid=1605110938&sr=8-9&linkCode=li2&tag=giftideas04d0-20&linkId=75851c1c9555b15dafd1e2e14e63386b&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07H331J4R&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07H331J4R" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3npVTwB',
    tags: ['toddler','gift','christmas','outdoor'],
  },
 
  {
    title: "Superhero Dress-up",
    description: "Okay, if you're kid is into super heros this is the best! Noelle doesn't know who super heros are yet, but I will DEFINITELY keep this dress-up set on my radar.  5 in 1? I love this idea.  Where were these things when I was a kid?!",
    imageLink: '<a href="https://www.amazon.com/Gream-baby-Superhero-Costumes-Birthday/dp/B07YJ45J9G/ref=as_li_ss_il?dchild=1&keywords=toddler+boy&qid=1605110938&sr=8-36&linkCode=li2&tag=giftideas04d0-20&linkId=f1d9bab9f1c7fb2653cfed07da197c6b&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07YJ45J9G&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07YJ45J9G" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3r3L3ic',
    tags: ['toddler','kid','christmas','dressup'],
  },
  {
    title: "Goodnight Construction Site Book",
    description: "Are all toddlers obsessed with excavators or only Blippi kids?  Well.. if your kid likes constuction this is probably the bed-time book for you.",
    imageLink: '<a href="https://www.amazon.com/Goodnight-Construction-Sherri-Duskey-Rinker/dp/1452111731/ref=as_li_ss_il?dchild=1&keywords=toddler+boy&qid=1605110938&sr=8-45&linkCode=li2&tag=giftideas04d0-20&linkId=cff65c2c03fc1bd67a7dbe77cd860dd8&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=1452111731&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=1452111731" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3ajMUJY',
    tags: ['toddler','gift','book','christmas'],
  },
  {
    title: "Toddler Socks",
    description: "For laundry purposes I really like all the socks to match.  When a pack comes with 2 pink, 2 blue, 2 green, it may be cute, but then I'm somehow stuck with 3 unmatched socks that sit in the drawer all alone until they're outgrown,  They call these \"boys\" socks but I think that's pretty funny.  They are socks for your feet.  Everyone has the same feet.  The ones marketed towards girls are never warm and rarely comfy.  These are my favorites and make a great Christmas gift add-on! I wear the adult version of these socks.  You can find them for every size feet. ",
    imageLink: '<a href="https://www.amazon.com/Hanes-Ultimate-12-Pack-Ankle-Socks/dp/B00S4GMPKA/ref=as_li_ss_il?dchild=1&keywords=hanes+toddler+socks&qid=1605112388&sr=8-9&th=1&psc=1&linkCode=li2&tag=giftideas04d0-20&linkId=fcdae0f9edf92ab10340378f69dea07c&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00S4GMPKA&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B00S4GMPKA" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/2WiQI5M',
    tags: ['toddler','gift','christmas','clothing'],
  },
  {
    title: "Slippers",
    description: "Noelle will randomly take her socks off on the coldest of days but really loves shoes.  It was time to get her some slippers.  I chose these because I have purple slippers and I thought she'd like to match Mommy.  I haven't given them to her yet but I'll let you know what she thinks!  There are many colors.",
    imageLink: '<a href="https://www.amazon.com/dp/B07HGZVF8T/ref=as_li_ss_il?_encoding=UTF8&psc=1&linkCode=li2&tag=giftideas04d0-20&linkId=ed2a6fdd318a06fba8b78dcfa5371edc&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07HGZVF8T&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07HGZVF8T" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3qXQWNX',
    tags: ['toddler','kid','christmas','clothing'],
  },
  {
    title: "Checkered Vans",
    description: "Noelle is currently about to outgrow her SECOND pair of checkered vans.  I had them as a teenager, and have a pair now and I love matching her.  Vans has a million designs and these are perfect for new walkers because of the non-slip grips on the bottom. I never really thought much about that until my cousin's 4 year old kept slipping at recess in her pretty sneakers and asked for shoes that wouldn't make her fall down, as she put it.  I got her a pair of vans her sister was really jealous.  She got a pair later that yaer. ",
    imageLink: '<a href="https://www.amazon.com/Vans-Kids-Classic-Slip-Checkerboard/dp/B00502C0VK/ref=as_li_ss_il?dchild=1&keywords=toddler+checkered+vans&qid=1605112845&sr=8-21&linkCode=li2&tag=giftideas04d0-20&linkId=6e688cd4913e095b4c74eadcba9540ac&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00502C0VK&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B00502C0VK" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/2Wly8tP',
    tags: ['toddler','clothing','christmas','kid'],
  },
  
  {
    title: "Dewalt Miter Saw",
    description: "I got this for my husband last year because in addition to home improvement attempts, he's been getting very into woodworking and buidling things.  He hasn't had much time on his hands but this is a really good saw for the prices.  The cost of these saws can vary dramatically, but after reading a lot of reviews and being very happy with other Dewalt items I've purchased in the past, I decided to go with this one.",
    imageLink: '<a href="https://www.amazon.com/dp/B07P8QTFRC/ref=as_li_ss_il?ie=UTF8&psc=1&linkCode=li2&tag=giftideas04d0-20&linkId=8ab5db2bc18f007c464ae3af93d9460d&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07P8QTFRC&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07P8QTFRC" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3mnYXIf',
    tags: ['tools','gift','christmas','dad','adult'],
  },
  {
    title: "Birth Stone Earrings",
    description: "If you're struggling to pick a gift for a mom or a grandma, and they have their ears pierced, get them earrings of the birth stone(s) of their children or grandchildren.  As a mom, sometimes I just want to subtly wear something that represents my daughter.",
    imageLink: '<a href="https://www.amazon.com/dp/B06XFR2RKJ/ref=as_li_ss_il?_encoding=UTF8&psc=1&linkCode=li2&tag=giftideas04d0-20&linkId=df71926808262588b6c796ae88c6a239&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B06XFR2RKJ&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B06XFR2RKJ" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3r6yNgL',
    tags: ['christmas','mom','jewlery','adult'],
  },
  { 
    title: "Play-Doh",
    description: "This is the ultimate play doh set.  I just got something like this for Noelle for her 2nd birthday.  I am looking forward to watching her sculpt her own creations.  You can also get a small play-doh set as a stocking stuffer!",
    imageLink: '<a href="https://www.amazon.com/Play-Doh-Multipack-47-Piece-Non-Toxic-Exclusive/dp/B085WNJ7JW/ref=as_li_ss_il?dchild=1&keywords=kids+toys&qid=1605117418&sr=8-4-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUFJNVFLSFhONDVKSVAmZW5jcnlwdGVkSWQ9QTAyODMwMDIyTTZDUzFMRFYyNzZBJmVuY3J5cHRlZEFkSWQ9QTA3NTI1NDUzU1FGRFZXWUo3RUkyJndpZGdldE5hbWU9c3BfYXRmJmFjdGlvbj1jbGlja1JlZGlyZWN0JmRvTm90TG9nQ2xpY2s9dHJ1ZQ==&linkCode=li2&tag=giftideas04d0-20&linkId=def8694464012175077c22efbeb3ec1c&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B085WNJ7JW&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B085WNJ7JW" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/2KAEcfa',
    tags: ['toddler','kid','christmas','toy'],
  },
  {
    title: "Mouse Trap Board Game",
    description: "My husband and I talk about this game more than the average adult couple.  We have such fond memories of it from our childhood that we're counting day the days until we can purchase this for Noelle!  We are a big board game family and we're really looking forward to growing our kid friendly board game collection.",
    imageLink: '<a href="https://www.amazon.com/Mouse-Trap-Game-Amazon-Exclusive/dp/B00000DMFD/ref=as_li_ss_il?dchild=1&keywords=mouse+trap+board+game&qid=1605120578&s=toys-and-games&sr=1-1-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUEzSFlCSkg4VlE0MzdKJmVuY3J5cHRlZElkPUEwODEyMjQ5Mko3NVhNVVlBQUlLUCZlbmNyeXB0ZWRBZElkPUEwNDM4NDY5NjkyRzdMRlRYSUtYJndpZGdldE5hbWU9c3BfYXRmJmFjdGlvbj1jbGlja1JlZGlyZWN0JmRvTm90TG9nQ2xpY2s9dHJ1ZQ==&linkCode=li2&tag=giftideas04d0-20&linkId=333664cb2ab18f207d50af7941cc790d&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00000DMFD&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B00000DMFD" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3r6g7xC',
    tags: ['kid','gift','christmas','game'],
  },
  {
    title: "Candy Land Board Game",
    description: "We gave this game to Noelle on Halloween during her candy scavenger hunt.  While she's a little bit young for it, she does enjoy moving her piece to the differnet colors.  I like to buy things a little early so she can build up the skill necessary to use them, rather than buying it after I think she'll understand.  I think she'll be a Candy Land master by her 3rd birthday.  This is the perfect 1st board. ",
    imageLink: '<a href="https://www.amazon.com/Retro-Candy-Land-1967-Game/dp/B01C3J4DNU/ref=as_li_ss_il?dchild=1&keywords=candyland&qid=1608147915&refinements=p_85:2470955011&rnid=2470954011&rps=1&sr=8-3&linkCode=li2&tag=giftideas04d0-20&linkId=bc2baaa4f8b8c03ea642c3246afce6eb&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B01C3J4DNU&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B01C3J4DNU" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3gToXu4',
    tags: ['toddler','kid','christmas','game'],
  },
  {
    title: "Ukulele",
    description: "Instruments are a very personal choice. HOWEVER, I recommend this gift for someone who has tried to pick up the guitar, but never quite got it.  I tried to learn the guitar for 20 years on and off and I just never got it.  I decided to buy a ukulele during quarantine and I picked it up enough to play my first song on day two.  It was so much easier and therefore fun.  I did a lot of research and I found this to be a great choice for a beginner on a budget without sacrificing sound quality.  If you need to buy for a music fan, or someone who needs a new hobbie, this might be a fun thing for them to try.",
    imageLink: '<a href="https://www.amazon.com/gp/product/B083XRB3PM/ref=as_li_ss_il?ie=UTF8&psc=1&linkCode=li2&tag=giftideas04d0-20&linkId=22a6efb31e4e75db636c637581c753ae&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B083XRB3PM&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B083XRB3PM" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/2KbJGxh',
    tags: ['adult','gift','christmas','music'],
  },
  {
    title: "Burrito Blanket",
    description: "This is such a silly gift, but I love it.  So many people like to wrap themselves up on the couch like a burrito, and how funny to actually look like a burrito.  ",
    imageLink: '<a href="https://www.amazon.com/CASOFU-Tortilla-Comfortable-Burrito-60inches/dp/B07QTHK8K9/ref=as_li_ss_il?dchild=1&keywords=burrito+blanket&qid=1605130753&s=toys-and-games&sr=1-5&linkCode=li2&tag=giftideas04d0-20&linkId=e4707d1462dcd39196114c3e099c3236&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07QTHK8K9&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07QTHK8K9" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3qZ7uFn',
    tags: ['adult','gift','christmas'],
  },
  {
    title: "Cuisinart Multiclad Pro Stainless Steel Cookware Set",
    description: "My husband really likes to cook (I know.. I'm really lucky).  When I ask him what his favorite gift of all time was, he answered this set of pans that he got for Christmas last year from my mom.  These are NOT non-stick so they're not for everything, but they're perfect for making sauces and cooking meat, he says.",
    imageLink: '<a href="https://www.amazon.com/Cuisinart-MCP-12N-Multiclad-Stainless-12-Piece/dp/B009JXPS6U/ref=as_li_ss_il?dchild=1&keywords=cuisinart+mcp-12n+multiclad+pro&qid=1605130884&sr=8-1-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUExSk81VEVVVTVOUlNBJmVuY3J5cHRlZElkPUEwMDA2OTkwMTdZUExKQjhGWjVGMSZlbmNyeXB0ZWRBZElkPUEwMTAzNzg2MlEzNDlTVzE1NzFPRSZ3aWRnZXROYW1lPXNwX2F0ZiZhY3Rpb249Y2xpY2tSZWRpcmVjdCZkb05vdExvZ0NsaWNrPXRydWU=&linkCode=li2&tag=giftideas04d0-20&linkId=4152f5d97fc7fd23d168e78297814e1e&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B009JXPS6U&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B009JXPS6U" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3oZmtNs',
    tags: ['adult','mealtime','christmas'],
  },
  {
    title: "Family Birthday Reminder Sign",
    description: "This is a perfect gift for a grandparent with a large family. My husband's grandpa has something like this and he literally never misses a birthday. ",
    imageLink: '<a href="https://www.amazon.com/ROSENICE-Birthday-Reminder-Calendar-Decoration/dp/B078MRQQ64/ref=as_li_ss_il?dchild=1&keywords=family+birthday+calendar+wood&qid=1608148124&sr=8-7&linkCode=li2&tag=giftideas04d0-20&linkId=56adec55b3c5b75c9e2542f1369406a2&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B078MRQQ64&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B078MRQQ64" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3npQgPf',
    tags: ['parent','gift','christmas','adult'],
  },
  {
    title: "Roomba Vacuum",
    description: "This obviously isn't a cheap gift.  I have an off brand robot vacuum and it does vacuum, but it does not map out the house, it can't even find it's base, and it certainly doesn't empty itself like this one!  I wanted to throw this on here in case you're stumped on a gift for someone with a high budget.  I personally feel this is the best gift ever for a parent.",
    imageLink: '<a href="https://www.amazon.com/iRobot-Vacuum-Wi-Fi-Connected-Mapping-Technology/dp/B085D3PR64/ref=as_li_ss_il?dchild=1&keywords=roomba&qid=1608148174&s=home-garden&sr=1-1-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUEyOFJBVEI4SkxMT1JLJmVuY3J5cHRlZElkPUEwMTEyODIzMjgxVlFNQklGWlkwJmVuY3J5cHRlZEFkSWQ9QTAwODI3ODgyTlRUQjExNFJLMFVBJndpZGdldE5hbWU9c3BfYXRmJmFjdGlvbj1jbGlja1JlZGlyZWN0JmRvTm90TG9nQ2xpY2s9dHJ1ZQ==&linkCode=li2&tag=giftideas04d0-20&linkId=ad3671ec8419e89f81e0358cc9907cf9&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B085D3PR64&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B085D3PR64" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/37ngZ9w',
    tags: ['adult','gift','christmas','parent'],
  },
   
  {
    title: "Exploding Kittens",
    description: "Another family game night suggestion!  I've been playing this game with friends since it came out.  It's a fast paced card game with some silly graphics.  It is recommended for ages 7 and up.  This is the kind of game that you can play without any skill, but you will get better over time.  That's why I think it's pefect for family game night.  They also have <a href='https://amzn.to/38ANGkI'>smaller packs</a> if you don't need a game for up to 10 players.",
    imageLink: '<a href="https://www.amazon.com/Exploding-Kittens-Card-Game-Family-Friendly/dp/B07CTXHNSL/ref=as_li_ss_il?ie=UTF8&aaxitk=cgaKKJpW2qHg-WG00dycHw&hsa_cr_id=5311052130201&pd_rd_r=0f460453-b804-49d3-891c-32cb91e57d9a&pd_rd_w=wmCpt&pd_rd_wg=wQpZn&ref_=sbx_be_s_sparkle_lsi2d_asin_2_img&linkCode=li2&tag=giftideas04d0-20&linkId=03c46e278b7758949ea126db74a614c6&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07CTXHNSL&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07CTXHNSL" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3nrzWxn',
    tags: ['adult','family','game','teen'],
  },
  {
    title: "Clock Puzzle",
    description: "I am always on the lookout for fun ways to teach my daughter things.  I've had my eye on this clock puzzle for a while to teach her the time, but she's not quite ready for that yet.  I wanted to put this out there for other parents that might be ready to start talking about clocks!",
    imageLink: '<a href="https://www.amazon.com/Coogam-Wooden-Shape-Color-Sorting/dp/B07QZ1W1S8/ref=as_li_ss_il?dchild=1&keywords=toddler&qid=1605195911&sr=8-99-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUExWFdDVDdPQ09DUU4mZW5jcnlwdGVkSWQ9QTA5MDU4NjkxSlZXWEZIT1VEU1dYJmVuY3J5cHRlZEFkSWQ9QTA1NTcxNDcyOENHQ0Q0OEtMSURNJndpZGdldE5hbWU9c3BfYXRmX25leHQmYWN0aW9uPWNsaWNrUmVkaXJlY3QmZG9Ob3RMb2dDbGljaz10cnVl&linkCode=li2&tag=giftideas04d0-20&linkId=d1828b6920edc31b294d3a1381a277bb&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07QZ1W1S8&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07QZ1W1S8" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/2WiRqjA',
    tags: ['toddler','educational','christmas','toy'],
  },
  {
    title: "My First Wipe Clean: ABC - Book",
    description: "My teacher friend is always getting Noelle the best gifts.  For her 2nd birthday she got her one of these wipe clean books, I didn't even know these existed! Noelle's not quite ready to write letters or numbers yet, but she has a lot of fun scribbling on the letters.  She's been improving with her tracing in just a week of trying so I imagine that within the next year she'll be pretty good! I like getting things that are a little advanced so she can build up to them rather than something she can master immediately. ",
    imageLink: '<a href="https://www.amazon.com/My-First-Wipe-Clean-ABC/dp/031252868X/ref=as_li_ss_il?dchild=1&keywords=my+first+wipe+clean+book&qid=1605196222&sr=8-5&linkCode=li2&tag=giftideas04d0-20&linkId=6c6b86d06f34b0c8cbc2411ea43e2a3a&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=031252868X&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=031252868X" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/34kz8CT',
    tags: ['toddler','gift','educational','toy'],
  },
  {
    title: "Wooden Puzzles",
    description: "Puzzles are a really good way for toddlers to learn hand-eye coordination.  My daughter has been pretty good at puzzles like these since she was 18 months, but that doesn't come without some frustration once in a while.  Nothing makes me more proud than seeing her frustrated that she can't fit a pieces, taking a break, and coming back and getting it in the right spot immediately.  I love seeing her solve her problem and dealing with frustration in a healthy way.  These puzzles also help her learn whatever the design is, animals, number, letters, shapes.  There are a ton of great toddler puzzles, get a bunch and rotate out the puzzle of thd day (or week... I get distracted).",
    imageLink: '<a href="https://www.amazon.com/Toddlers-Alphabet-Preschool-Educational-Montessori/dp/B07Z85CJ8J/ref=as_li_ss_il?dchild=1&keywords=wood+toddler+puzzle&qid=1605196530&sr=8-5&linkCode=li2&tag=giftideas04d0-20&linkId=49c353f1bf6a86a255f111eff8afc7e9&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07Z85CJ8J&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07Z85CJ8J" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/37pHs6o',
    tags: ['toddler','gift','christmas','toy'],
  },
  {
    title: "Jump Rope",
    description: "Jumping rope is a really good excercise for kids and it also teaches them how to react and time their jumps.  These jump ropes are neat because they can be adjusted to fit the height of the user.  With so many kids home from school due to Covid-19, this helps bring a fun gym class activity to you.",
    imageLink: '<a href="https://www.amazon.com/OHYAIAYN-Soft-Beaded-Adjustable-Tangle/dp/B07QWP31VS/ref=as_li_ss_il?dchild=1&keywords=kids+jump+rope&qid=1605196849&sr=8-6&linkCode=li2&tag=giftideas04d0-20&linkId=83b926a3ee2b8d69653ec2f1269c3e0a&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07QWP31VS&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07QWP31VS" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3npwbbC',
    tags: ['kid','gift','christmas','toy'],
  },
  {
    title: "Bicycle Lights",
    description: "I have 2 neighbors that are outside on thier bicycles from morning until night.  If you know a kid with a similar passion for riding their bike with friends, these lights might be a hit.  Not only do these look so cool (they come in differnet colors) but they're also a safety feature disguised as a decoration.  I know it's never a good idea to be riding a bike if it's not bright out, but no matter what the time, these lights will help with visibility. ",
    imageLink: '<a href="https://www.amazon.com/Activ-Life-Bicycle-Lights-Multicolor/dp/B075Y9GQR6/ref=as_li_ss_il?dchild=1&keywords=toys+for+7+year+old&qid=1605197379&sr=8-4-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUEyVjZDTjAySlBTS1UxJmVuY3J5cHRlZElkPUEwODYxMjU5M0ExSUFLUklXTkFCRCZlbmNyeXB0ZWRBZElkPUEwNTMyMjA2M0pBVEw3QVAxTUgwUyZ3aWRnZXROYW1lPXNwX2F0ZiZhY3Rpb249Y2xpY2tSZWRpcmVjdCZkb05vdExvZ0NsaWNrPXRydWU=&linkCode=li2&tag=giftideas04d0-20&linkId=78868fc9b7aae452ff03bfd5fb1c7491&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B075Y9GQR6&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B075Y9GQR6" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3r6HpE7',
    tags: ['kid','adult','christmas','outdoor'],
  },
  {
    title: "Cricut",
    description: "I'll admit, I had my cricut for almost a full year before I used it.  But once I got started, I loved it! If you know someone that's into crafting or DYI, the cricut can help them make high quality shirts, and lables, and mugs, and you name it.  I have even made birthday decorations with mine.",
    imageLink: '<a href="https://www.amazon.com/Cricut-Explore-Air-2-Mint/dp/B01GSOMVRG/ref=as_li_ss_il?dchild=1&keywords=cricut&qid=1608151091&s=arts-crafts&sr=1-4&linkCode=li2&tag=giftideas04d0-20&linkId=02386f37163a88b9ba77c0295d54bb7d&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B01GSOMVRG&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B01GSOMVRG" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3ahxB4g',
    tags: ['adult','gift','christmas','parent'],
  },
  {
    title: "Coloring Book",
    description: "My husband got this coloring book for Noelle and she has really loved it.  It's huge and the pages are perforated so it's really easy to rip out the one you want to color.  Every kid should have a coloring book handy!",
    imageLink: '<a href="https://www.amazon.com/Crayola-Awesome-Coloring-Animal-Pages/dp/B07MJ7N6VW/ref=as_li_ss_il?ac_md=1-0-VW5kZXIgJDU=-ac_d_pm&cv_ct_cx=coloring+book&dchild=1&keywords=coloring+book&pd_rd_i=B07MJ7N6VW&pd_rd_r=78d28467-b1bc-4579-8043-cd98b60d5c02&pd_rd_w=tEXW6&pd_rd_wg=1SFIf&pf_rd_p=c06fc8c3-5a01-4c0e-912c-5bd65911ecca&pf_rd_r=3DSZYA1NM3PJCPVM6BF1&psc=1&qid=1605197860&sr=1-1-22d05c05-1231-4126-b7c4-3e7a9c0027d0&linkCode=li2&tag=giftideas04d0-20&linkId=dbbe1133a9762d0d9e7cad37ef5b9cbe&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07MJ7N6VW&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07MJ7N6VW" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/2KeAFnd',
    tags: ['toddler','gift','christmas','toy'],
  },
  
  {
    title: "Willow Tree Scultpture",
    description: "These willow sculptures are so touching to me.  I always thought they were beautiful, but now that I am a mom they carry a lot more meaning.  They have a lot of different ones, you can probably <a href='https://amzn.to/32AUDhJ'>find one</a> that reminds you of your family.  This is a particualrly good gift for mom or grandma.",
    imageLink: '<a href="https://www.amazon.com/Willow-Tree-Generations-sculpted-hand-painted/dp/B001OYQ4QK/ref=as_li_ss_il?ref_=ast_sto_dp&linkCode=li2&tag=giftideas04d0-20&linkId=76f225e4e9576172db9c2f6411373bd0&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B001OYQ4QK&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B001OYQ4QK" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/2Kv2K9y',
    tags: ['adult','gift','christmas'],
  },
  
  {
    title: "KidKraft Dollhouse",
    description: "My daughter seems a little too young to responsibly play with small dolls, but every year I look at this dollhouse and wonder when she'll be the right age for it.  I'm thinking next year when she turns 3.  I always hear people raving about this brand.  It's mostly wood which elevates a toy and seems to make it more durable than the plastic alternatives.",
    imageLink: '<a href="https://www.amazon.com/KidKraft-Annabelle-Dollhouse-with-Furniture/dp/B002OED6I8/ref=as_li_ss_il?dchild=1&keywords=kidkraft&qid=1608168701&sr=8-8&linkCode=li2&tag=giftideas04d0-20&linkId=44fbce63682ad176db5871d002812ce0&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B002OED6I8&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B002OED6I8" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3ajYPak',
    tags: ['toddler','gift','christmas','kid'],
  },
  {
    title: "Dewalt Drill",
    description: "We have had this drill for a few years and it's way more powerful than any other drill we've ever had.  It's a good gift for someone with a new home, or anyone looking for a new drill.",
    imageLink: '<a href="https://www.amazon.com/Dewalt-DCD771C2-Cordless-Lithium-Ion-Compact/dp/B00ET5VMTU/ref=as_li_ss_il?dchild=1&keywords=drill+dewalt&qid=1605403835&sr=8-1&linkCode=li2&tag=giftideas04d0-20&linkId=faba5e5c8ce1855dcb80f6127c313549&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00ET5VMTU&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B00ET5VMTU" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/37sqapg',
    tags: ['adult','gift'],
  },
  {
    title: "Reciprocating Saw",
    description: "My husband say this is the most useful saw he owns because it can help with DIY and demo.  It's a saw we didn't have right away when we bought our home, but quickly found ourselves needing.  ",
    imageLink: '<a href="https://www.amazon.com/DEWALT-Reciprocating-Saw-10-Amp-DWE304/dp/B00ODN1CP2/ref=as_li_ss_il?ac_md=4-0-VW5kZXIgJDEwMA==-ac_d_pm&cv_ct_cx=reciprocating+saw+dewalt&dchild=1&keywords=reciprocating+saw+dewalt&pd_rd_i=B00ODN1CP2&pd_rd_r=f03a8577-693b-423b-8992-14170835f16a&pd_rd_w=8opNw&pd_rd_wg=2vmFZ&pf_rd_p=68f25c26-6854-442e-9296-f746545e76bb&pf_rd_r=9P6DB74J78EDX12QNKVY&psc=1&qid=1605404234&sr=1-1-22d05c05-1231-4126-b7c4-3e7a9c0027d0&linkCode=li2&tag=giftideas04d0-20&linkId=bea435fe997323eee498d23456f6f7bd&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00ODN1CP2&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B00ODN1CP2" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3r2MrSf',
    tags: ['adult','gift'],
  },
  {
    title: "Magnetic Blocks",
    description: "My daughter's not old enough for these yet but I've heard a lot of moms say that these things can keep their kids occupied for hours.",
    imageLink: '<a href="https://www.amazon.com/Playmags-100-Piece-Super-Set/dp/B00EUBD14C/ref=as_li_ss_il?dchild=1&keywords=magna+tiles&qid=1608168820&sr=8-1-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUEzTFUwTFdaOVM5NDdNJmVuY3J5cHRlZElkPUEwNjA2NDIyMldKOE9MT082WlFOUSZlbmNyeXB0ZWRBZElkPUEwNjQxMDU1MzRGUDM4S05SQklBJndpZGdldE5hbWU9c3BfYXRmJmFjdGlvbj1jbGlja1JlZGlyZWN0JmRvTm90TG9nQ2xpY2s9dHJ1ZQ==&linkCode=li2&tag=giftideas04d0-20&linkId=28eea719534259afc46327e55f9ed344&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00EUBD14C&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B00EUBD14C" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/37o5Ipq',
    tags: ['kid','gift'],
  },
  
  {
    title: "Paint by Sticker",
    description: "When I was a kid stickers, and color by number were my favorites.  I'm literally about to order this for myself because this looks so fun. I did not know this existed until today.",
    imageLink: '<a href="https://www.amazon.com/Paint-Sticker-Kids-Animals-Pictures/dp/0761189602/ref=as_li_ss_il?dchild=1&keywords=kids+gifts&qid=1605404775&sr=8-54&linkCode=li2&tag=giftideas04d0-20&linkId=62bb8d389954e1032687e75a7ce879f3&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=0761189602&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=0761189602" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/2Wpfdy9',
    tags: ['gift','christmas','kid'],
  },
  {
    title: "Magnetic Wrist Band",
    description: "This is probably one of the most successful stocking stuffers I ever gave.  I have been guilty of holding nails in my mouth while hanging a picture which is definitely a bad idea.  This wrist band (I gave one to my husband, my dad, my step-dad, and all of my uncles) has been such an unexpected help during many projects.",
    imageLink: '<a href="https://www.amazon.com/MagnoGrip-311-090-Magnetic-Wristband/dp/B000WU9LCQ/ref=as_li_ss_il?dchild=1&keywords=stocking+stuffer&qid=1605405929&sr=8-61&linkCode=li2&tag=giftideas04d0-20&linkId=5cd0822a72819379823f4eec1c37a7b8&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B000WU9LCQ&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B000WU9LCQ" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3gWiI8F',
    tags: ['adult','stocking','gift'],
  },
  {
    title: "Door Opener Tool",
    description: "I think these days most people are trying to avoid touching doors and keypads.  I ordered this two pack for my husband and I but it's the pefect size to put into a stocking! ",
    imageLink: '<a href="https://www.amazon.com/gp/product/B08FRQXTHL/ref=as_li_ss_il?ie=UTF8&psc=1&linkCode=li2&tag=giftideas04d0-20&linkId=28cd4ac99a50def8a659ab298fea0f94&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08FRQXTHL&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B08FRQXTHL" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/37oS5WU',
    tags: ['adult','stocking'],
  },
  {
    title: "Drill Extender",
    description: "If you have ever tried to screw something in in a tight space and the drill wouldn't fit into the space, this is what you need.  I bought this for my husband last Christmas but I think I've used it more than him.  I was pretty surprised at how well it works.  I got one for my dad also. ",
    imageLink: '<a href="https://www.amazon.com/gp/product/B07KWVXDY4/ref=as_li_ss_il?ie=UTF8&psc=1&linkCode=li2&tag=giftideas04d0-20&linkId=b3481ab8af0b0a2d02678a64889c86d4&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07KWVXDY4&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07KWVXDY4" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3oX2PBS',
    tags: ['adult','stocking'],
  },
  {
    title: "Wireless Charger",
    description: "We had another wireless charger but the light stayed on the entire time the phone was charging and since we charge our phones next to our beds, it was not working out.  After a lot of searching, we found this charger that does not stay lit while charging.  It works really well and we've had it for about a year.",
    imageLink: '<a href="https://www.amazon.com/dp/B079KZ49PJ/ref=as_li_ss_il?ie=UTF8&linkCode=li2&tag=giftideas04d0-20&linkId=f288405d0383f2c8e63fafbb154e1b36&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B079KZ49PJ&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B079KZ49PJ" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/2WkAfOn',
    tags: ['adult','stocking'],
  },
  {
    title: "Little Trampoline",
    description: "Trampolines come with an injury risk, but my toddler jumps on the couch and her bed EVERY CHANCE SHE GETS.  I'd rather her jump somewhere where she's less likely to fall onto the floor from a big height.  We have a large trampoline, but this is the one I was considering before we made that choice.",
    imageLink: '<a href="https://www.amazon.com/dp/B00AU0O7QI/ref=as_li_ss_il?coliid=I2SQKYL8YACWI8&colid=ZUZN8BTRHWVE&psc=1&ref_=lv_ov_lig_dp_it&linkCode=li2&tag=giftideas04d0-20&linkId=b2e93335ff5b9b615e61e483c6642d4d&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00AU0O7QI&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B00AU0O7QI" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/38fy9VC',
    tags: ['toddler'],
  },
  {
    title: "Art Cart",
    description: "Having a place to organize my kid's art supplies was really one of the best decisions I made.  I have this cart without the organizational inserts and I use it to store all of Noelle's art stuff like crayons, coloring books, construction paper, play-doh, paint, etc.  Before I had a set place to store these thinsg, we rarely used them.  I really want to sure I encourage creativity and don't misplace her art supplies and this cart has really changed the game.  Toddler gifts do not all have to be toys.  This will help you both.",
    imageLink: '<a href="https://www.amazon.com/alvorog-Rolling-Multifunction-Assembly-Bathroom/dp/B0834WTCCC/ref=as_li_ss_il?dchild=1&keywords=art+cart&qid=1605886960&sr=8-6&linkCode=li2&tag=giftideas04d0-20&linkId=77540110f907fc880ad2c08be987fa15&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0834WTCCC&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B0834WTCCC" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/2IUM9eP',
    tags: ['toddler','kid'],
  },
  {
    title: "Nesting Dolls",
    description: "When I was a kid I absolutely loved my nesting doll.  I was actually just thiking about them recently and decided I want to order a set for my daughter for Christmas.  I thought it was neat that they offer different types of designs now that kids might find fun to play with. ",
    imageLink: '<a href="https://www.amazon.com/Vingi-Nesting-Matryoshka-Russian-Handmade/dp/B071D7N796/ref=as_li_ss_il?dchild=1&keywords=fairy+nesting+dolls&qid=1605892238&s=toys-and-games&sr=1-7&linkCode=li2&tag=giftideas04d0-20&linkId=9043f6cbb23077eab5bc227d65201ce1&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B071D7N796&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B071D7N796" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/34iyPsa',
    tags: ['toddler','kid'],
  },
  {
    title: "Uno",
    description: "I'm all about family game night and Uno is one of those games that you can play with all different skill levels.  With covid-19 preventing a lot of families from pursuing trips and outdoor experiences, family game nights are more important than ever!  This is a great game that everyone will can enjoy together..",
    imageLink: '<a href="https://www.amazon.com/Mattel-Games-Official-Amazon-Exclusive/dp/B07P6MZPK3/ref=as_li_ss_il?dchild=1&keywords=uno&qid=1608169121&sr=8-1&linkCode=li2&tag=giftideas04d0-20&linkId=a581cea71139c39d400fc01b96585aff&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07P6MZPK3&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07P6MZPK3" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3890o8x',
    tags: ['kid','experience',],
  },
  {
    title: "Electric Toothbrush",
    description: "My 2 year old is not that great at brushing her teeth but this frozen electric toothbrush makes it a lot easier.  At least the electric brush can clean her teeth a little even if she won't let us move the brush back and fourth.  They have a lot of different characters available.  She'll be getting another on in her stocking.  Gifts can be practical!",
    imageLink: '<a href="https://www.amazon.com/Oral-B-Pro-Health-Battery-Toothbrush-featuring/dp/B01AKGRSCG/ref=as_li_ss_il?dchild=1&keywords=kids+electric+toothbrush+disney&qid=1605902420&sr=8-4&linkCode=li2&tag=giftideas04d0-20&linkId=008966debaac037887acfb26d7dacb29&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B01AKGRSCG&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B01AKGRSCG" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/2Wm9Ek3',
    tags: ['toddler','kid','stocking'],
  },
  {
    title: "Reversible Octopus Plushie",
    description: "I ordered one of these for Noelle and I'm hoping it wil fit in her stocking.  I've heard these toys are really good for helping kids express their feelings.  One way it's happy, and if you flip it inside out it's sad/mad.  There are a lot of different colors available. ",
    imageLink: '<a href="https://www.amazon.com/dp/B07H4Q9BRV/ref=as_li_ss_il?_encoding=UTF8&psc=1&linkCode=li2&tag=giftideas04d0-20&linkId=39c7d2d28314cf1752c24312f33ab15f&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07H4Q9BRV&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07H4Q9BRV" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3oU5QCM',
    tags: ['toddler','kid', 'stocking'],
  },
  {
    title: "Stacking Frogs Counting Toy",
    description: "I really love toys that are educational.  With the colors and the frogs, this seems like a really good way to learn to count.  It's recommended for 3 and up so I'll put this on the list for next year!",
    imageLink: '<a href="https://www.amazon.com/dp/B08JS2JVCX/ref=as_li_ss_il?coliid=I220CZR9PP0SHX&colid=20GG0NDI117G7&psc=1&ref_=lv_ov_lig_dp_it_im&linkCode=li2&tag=giftideas04d0-20&linkId=37cfff5983ef2b30b50bccc2535530de&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08JS2JVCX&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B08JS2JVCX" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3p25TNg',
    tags: ['toddler'],
  },
  {
    title: "Bitty Baby",
    description: "I know they're expensive, but I got a bitty baby for my daughter last year because she really loves dolls.  When I was a kid I loved dolls too and it was near impossible to find clothing and accessories that fit my dolls.  I decided to get my daughter a bitty baby strickly because they make a lot of accessories for them and if she does want to put those things on her wish lists as she grows up, she'll be able to.  It's a really good quality doll and you can find sets at Costco sometimes that come with some clothing and accessories.",
    imageLink: '<a href="https://www.amazon.com/American-Girl-Bitty-Light-Bodysuit/dp/B016BYNW76/ref=as_li_ss_il?dchild=1&keywords=bitty+baby&qid=1606064258&sr=8-1&linkCode=li2&tag=giftideas04d0-20&linkId=83c238f2ad53c0e206fe114127ddcb32&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B016BYNW76&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B016BYNW76" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/2LBabfS',
    tags: ['toddler'],
  },
  {
    title: "Crocs",
    description: "Crocs are not weather appropriate for Christmas time in our area, but as I've mentioned, don't forget about the rest of the year, ESPECIALLY if your child is born near Christmas.  My daughter's birthday is in November so we have a lot of gifts coming in all at once and then we go 10 months with no true gift holidays.  I try to think ahead to the whole year when coming up with her list.  I got a pair of crocs this year and it was almost life changing.  I laugh at how silly that sounds but I really love them and I feel like I've been missing out.  These will be great for the pool, water table, or any other time she needs to slip on a shoe quickly.",
    imageLink: '<a href="https://www.amazon.com/dp/B071FBKVFN/ref=as_li_ss_il?coliid=I2L3VB53LS7D36&colid=20GG0NDI117G7&psc=1&ref_=lv_ov_lig_dp_it_im&linkCode=li2&tag=giftideas04d0-20&linkId=829cda06323f5a4e89a3ac01fe5cb624&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B071FBKVFN&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B071FBKVFN" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/2KyqSrI',
    tags: ['toddler','kid'],
  },
  {
    title: "Matching Game",
    description: "I made a matching game at home with my printer and laminator and it's been working surprisingly well.  The pieces are getting bent though and since Noelle does seem to enjoy it, I put a real memory game on her Christmas wish list.  These pieces look like they're not cardboard and I think it'll be easier to use than the current home-made version.  I am looking forward to playing this with her as she grows so she can work on her memory and match making skills.  I think this game is really important for all toddlers to play.",
    imageLink: '<a href="https://www.amazon.com/dp/B07MFTSFW1/ref=as_li_ss_il?coliid=I277W3X7GU0656&colid=20GG0NDI117G7&psc=1&ref_=lv_ov_lig_dp_it&linkCode=li2&tag=giftideas04d0-20&linkId=edbc4e270944497761404fc6a823bb46&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07MFTSFW1&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07MFTSFW1" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3oZie4x',
    tags: ['toddler'],
  },
  {
    title: "Pikler Triangle",
    description: "I've heard a lot of really good things about pikler triangles.  Noelle climbs on everything and it's getting dangerous.  I decided to put the pikler triangle on her wish list because if she has a designated space to climb it should be safer.  I chose this one because it can fold to be put away.  We have limited space and knowing that I will be able to flatten it to put in her closet is what we need.",
    imageLink: '<a href="https://www.amazon.com/CASSARO-Large-Foldable-Climbing-Triangle/dp/B08KJKJ36D/ref=as_li_ss_il?dchild=1&keywords=pikler&qid=1606066354&sr=8-19&linkCode=li2&tag=giftideas04d0-20&linkId=3ff72e493843336944981d48719aea0c&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08KJKJ36D&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B08KJKJ36D" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/34n6VeF',
    tags: ['toddler'],
  },
  {
    title: "Giant Connect 4",
    description: "I've heard a lot of parents say that their kids have too many indoor toys and they want ideas for fun outdoor activities.  I saw this game at a bar once and I didn't even realize it was possible to buy them for your own yard.  This looks like so much fun and as soon as Noelle's old enough to understand the rules of connect 4 I'm going to add this to the wish list for sure.  I also have my eye on this as a Christmas gift for my cousin's kids.",
    imageLink: '<a href="https://www.amazon.com/Giant-Row-Connect-Game-Waterproof/dp/B08658G3K2/ref=as_li_ss_il?ref_=ast_sto_dp&linkCode=li2&tag=giftideas04d0-20&linkId=b70d3b893224aae8a5b6b1470cc56510&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08658G3K2&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B08658G3K2" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/2Kfhb1N',
    tags: ['kid', 'outdoor'],
  },
  {
    title: "Sorry Boardgame",
    description: "I know I have recommended a lot of board games, but I really think they're a great way to get the family together at one table without electronics.  Sorry is a really easy game, but it's still one of my favorites.  I love the suspense it creates and it's really a pretty fun game for the whole family.  It's really a must for the family game closet, I actually have 2 sets,",
    imageLink: '<a href="https://www.amazon.com/dp/B00000IWD0/ref=as_li_ss_il?ie=UTF8&linkCode=li2&tag=giftideas04d0-20&linkId=0d27dd6361a5f1b8f4c7b5a52901d272&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00000IWD0&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B00000IWD0" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3p0UVHK',
    tags: ['kid'],
  },
  {
    title: "Crayola Paint Set",
    description: "I always hear parents say their kids have too many toys and they don't know what else to get them.  Arts and crafts are always a good gift.  I know they are messy, and I know they're not for every day.  My 2 year old probably paints once a month but I think it's really important to let toddlers and kis be creative.  I really like this paint and it is washable like they say.  They have larger sets with more colors too!",
    imageLink: '<a href="https://www.amazon.com/Crayola-Paint-Craft-Supplies-Amazon/dp/B07Z9QTB66/ref=as_li_ss_il?dchild=1&keywords=crayola+paint&qid=1606232529&s=toys-and-games&sr=1-7&linkCode=li2&tag=giftideas04d0-20&linkId=a84a6277a0e22d38d271dc7d5449b1e2&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07Z9QTB66&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07Z9QTB66" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/37n7h70',
    tags: ['toddler','kid'],
  },
  {
    title: "Kid's Art Easel",
    description: "I've talked a lot about how important I think art is for kids.  I LOVED my easel when I was a kid and I think Noelle is getting to the age where she can start using one.  I chose this one for her wish list because it looks nice, the height is adjustable, and it comes witha  white borad and black board!",
    imageLink: '<a href="https://www.amazon.com/Hape-Winning-Wooden-Accessories-Replacement/dp/B081S3NJ17/ref=as_li_ss_il?ascsub&cv_ct_cx=toddler+easel&cv_ct_id=amzn1.osa.7a6d06f5-c241-47f1-a580-12685195dc74.ATVPDKIKX0DER.en_US&cv_ct_pg=search&cv_ct_we=asin&cv_ct_wn=osp-single-source-gl-ranking&dchild=1&keywords=toddler+easel&pd_rd_i=B081S3NJ17&pd_rd_r=13f461b5-bf59-4236-a6e6-6eb903372fd7&pd_rd_w=5JvBN&pd_rd_wg=NHz38&pf_rd_p=f78282ee-db56-4d41-99a2-17abf93837bc&pf_rd_r=0X6BQYQTJG72NWZ4J9AX&qid=1606233142&sr=1-1-d9dc7690-f7e1-44eb-ad06-aebbef559a37&th=1&linkCode=li2&tag=giftideas04d0-20&linkId=855b61ab76299932f3d466ca64e14ec4&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B081S3NJ17&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B081S3NJ17" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/34jJmU5',
    tags: ['toddler','kid'],
  },
  {
    title: "Blendtec Blender",
    description: "We recently replaced our $15 blender from college with this.  Needless to say it was like night and day.  This is really amazing for the price.  I know they make $600 blenders that might be slightly better than this, but this one has a really strong motor and makes smoothies really quickly.  We're really happy with this purchase and it might be a good upgrade for someone that's been struggling with a regular blender.",
    imageLink: '<a href="https://www.amazon.com/Blendtec-Original-Professional-Grade-Pre-programmed-10-speeds/dp/B000GIGZXM/ref=as_li_ss_il?dchild=1&keywords=blendtec+blender&qid=1606233370&sr=8-3&linkCode=li2&tag=giftideas04d0-20&linkId=f4d488d393b8cfd7652947f8b379591f&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B000GIGZXM&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B000GIGZXM" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/3r7T1XM',
    tags: ['adult'],
  },
  {
    title: "3D Printer",
    description: "My husband and I have been talking about one of these for a really long time.  They're finally starting to become affordable for a begginer to mess around on.  A good gift for any tech people on your list, or people that like to create things!",
    imageLink: '<a href="https://www.amazon.com/gp/product/B07J9QGP7S/ref=as_li_ss_il?ie=UTF8&psc=1&linkCode=li2&tag=giftideas04d0-20&linkId=935bf2d4a27018d4fb5ee1793e3dbd3c&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07J9QGP7S&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07J9QGP7S" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/2WjJleu',
    tags: ['adult'],
  },
  {
    title: "Boos Block - Cutting Board",
    description: "A nice cutting board is a good gift for a young couple, especially if they are just starting out and don't have a ton for their kitchen yet! We've had a cutting board like this for a few year and it makes prepping veggies a lot easier!  ",
    imageLink: '<a href="https://www.amazon.com/gp/product/B07H1CND6G/ref=as_li_ss_il?ie=UTF8&psc=1&linkCode=li2&tag=giftideas04d0-20&linkId=98373469f7bdc91f7c1258b010ea4ac7&language=en_US" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07H1CND6G&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=giftideas04d0-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=giftideas04d0-20&language=en_US&l=li2&o=1&a=B07H1CND6G" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
    shortLink: 'https://amzn.to/38bwVuD',
    tags: ['adult'],
  },
 
 

  // {
  //   title: "",
  //   description: "",
  //   imageLink: '',
  //   shortLink: '',
  //   tags: ['toddler','kid', 'outdoor'],
  // },



];

export default affLinks;
